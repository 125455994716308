.products-wrapper {
    animation: slideUp 300ms linear;
    animation-delay: 150ms;
  }
  @media (max-width: 991px) {
    .products-wrapper {
      padding-top: 68px;
    }
  }
  @media (max-width: 480px) {
    .products-wrapper {
      padding-top: 52px;
    }
  }
  .products {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    align-items: flex-end;
    padding: 16px;
    max-width: 960px;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    .products {
      padding: 8px;
    }
  }
  .products .product {
    flex-basis: calc(33% - 32px);
    -webkit-flex-basis: calc(33% - 32px);
  }
  @media (max-width: 480px) {
    .products .product {
      flex-basis: calc(50% - 16px);
      -webkit-flex-basis: calc(50% - 16px);
      min-height: 313px;
    }
    .products .product .product-name {
      height: 20px;
      overflow: hidden;
    }
    .products .product .product-action {
      padding: 16px 8px;
    }
  }
  @media (max-width: 320px) {
    .products .product {
      flex-basis: 100%;
      -webkit-flex-basis: 100%;
    }
  }
  .fadeIn-enter {
    opacity: 0.01;
  }
  .fadeIn-enter.fadeIn-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .fadeIn-exit {
    opacity: 1;
  }
  .fadeIn-exit.fadeIn-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
  .product:nth-child(1) {
    transition-delay: 200ms;
  }
  .product:nth-child(2) {
    transition-delay: 200ms;
  }
  .product:nth-child(3) {
    transition-delay: 400ms;
  }
  .product:nth-child(4) {
    transition-delay: 600ms;
  }
  .product:nth-child(5) {
    transition-delay: 800ms;
  }
  .product:nth-child(6) {
    transition-delay: 1000ms;
  }
  .product:nth-child(7) {
    transition-delay: 1200ms;
  }
  .product:nth-child(8) {
    transition-delay: 1400ms;
  }
  .product:nth-child(9) {
    transition-delay: 1600ms;
  }
  .product:nth-child(10) {
    transition-delay: 1800ms;
  }
  .product:nth-child(11) {
    transition-delay: 2000ms;
  }
  .product:nth-child(12) {
    transition-delay: 2200ms;
  }
  .product.loading {
    animation-duration: 600ms;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: contentLoader;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
    background-size: 1000px 104px;
    height: 200px;
    position: relative;
    overflow: hidden;
  }
  .product.loading .product-image {
    height: 168px;
    width: 168px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.25);
  }
  @media (max-width: 768px) {
    .product.loading .product-image {
      width: auto;
    }
  }
  .product.loading .product-text, .product.loading .product-button {
    margin: 12px 16px;
    height: 16px;
    background: rgba(255, 255, 255, 0.25);
  }
  .product.loading .product-button {
    position: absolute;
    bottom: 8px;
    width: 168px;
    height: 36px;
  }
  @media (max-width: 768px) {
    .product.loading .product-button {
      width: auto;
    }
  }
  @keyframes contentLoader {
    0% {
      background-position: -420px 0;
    }
    100% {
      background-position: 420px 0;
    }
  }
  .no-results {
    text-align: center;
    max-width: 520px;
    margin: 0 auto;
  }
  .no-results img {
    width: 320px;
    margin: 16px 0 32px 0;
    opacity: 0.33;
  }
  .no-results h2 {
    margin-bottom: 16px;
    color: #666;
  }
  .no-results p {
    color: #999;
  }
